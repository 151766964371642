<template>
  <div>
    <div v-if="type === 'job-seeker'">
      <Header />
    </div>

    <div v-if="type === 'admin'" class="flex flex__center mt-6">
      <div class="w-10">
        <img
          style="width: 100%"
          src="@/assets/icons/infohub-blue-logo.svg"
          alt="infohob-blue-logo"
        />
      </div>
    </div>

    <div
      class="auth__card-wrapper flex flex__center flex__item-center"
      :class="[type ? type : '']"
    >
      <div
        :class="[
          card ? 'auth__card text-center' : '',
          wide ? 'auth__card-wide' : ''
        ]"
      >
        <slot>
          <div>Card</div>
        </slot>
      </div>
    </div>
    <br />
    <br />
    <br />

    <slot name="footer"></slot>
  </div>
</template>

<script>
import Header from "../../components/Header";

export default {
  props: {
    card: {
      type: Boolean,
      default: true
    },
    wide: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "job-seeker"
    }
  },
  name: "AuthLayout",
  components: {
    Header
  }
};
</script>

<style scoped>
.auth__card-wrapper--fixed {
  margin-top: 108px;
}
</style>
