<template>
  <header
    class="flex flex__spaced header"
    :class="{
      'header__with-bg': $route.name === 'onboarding' || headerWithBG,
      'fixed-header': fixed || fixed === ''
    }"
  >
    <a href="https://infohob.com">
      <img src="../../assets/icons/infohub-blue-logo.svg" alt="infohob-logo" />
    </a>

    <div class="flex flex__item-center">
      <slot name="links-before"></slot>
      <Button
        theme="blue"
        class="w-16"
        :mobile="true"
        @click="goToPage"
        v-if="ctaText && showLoginBtn"
      >
        {{ ctaText }}
      </Button>
      <slot name="links-after"></slot>
    </div>
  </header>
</template>

<script>
import Button from '../../components/Button';

export default {
  name: 'Header',
  props: {
    showLoginBtn: {
      type: Boolean,
      default: true
    },
    headerWithBG: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Button
  },
  computed: {
    ctaText() {
      switch (this.$route.name) {
        case 'login':
          return 'Create Account';
        case 'resetPassword':
        case 'onboarding':
          return '';
        case 'landing-page':
          return 'Create Account';
        default:
          return 'Log in';
      }
    }
  },
  methods: {
    goToPage() {
      switch (this.ctaText) {
        case 'Log in':
          this.$router.push('/force-logout');
          return;
        case 'Create Account':
          this.$router.push({ name: 'createAccount' });
          return;
        default:
          return;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use '../../assets/scss/mixins/index.scss' as *;

.header {
  padding: 2rem 4rem 0;

  @include query('mobile') {
    padding: 2rem 1.6rem 0;
  }

  &__with-bg {
    background: #fafafa;
  }
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: #fff;
}
</style>
