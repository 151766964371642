// import { axiosGuest, axiosPrivate } from "./axiosInstance";
import axiosPublic from "../index";
import API_ENDPOINTS from "../../const/index";
import jwtDecode from "jwt-decode";
import queryConcatenator from "../../utils/queryConcatenator";
const axiosPrivate = axiosPublic; // temoorary solution

export default {
  async signIn(payload) {
    try {
      const response = await axiosPublic.post(
        API_ENDPOINTS.ADMIN_LOGIN,
        payload
      );

      if (!response.data.error) {
        const {
          access_token: token,
          refresh_token,
          on_boarding
        } = response.data.data;
        axiosPublic.defaults.headers.Authorization = `Bearer ${token}`;

        console.log(jwtDecode(token));

        const {
          realm_access,
          resource_access,
          preferred_username: username,
          email
        } = jwtDecode(token);

        return Promise.resolve({
          realm_access,
          resource_access,
          username,
          email,
          token,
          refresh_token,
          on_boarding
        });
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  forgotPassword(payload) {
    return axiosPublic.put(API_ENDPOINTS.ADMIN_FORGOT_PASSWORD, payload);
  },
  changePassword(payload) {
    return axiosPublic.put(API_ENDPOINTS.ADMIN_CHANGE_PASSWORD, payload);
  },
  getAllJobs() {
    return axiosPublic.get(API_ENDPOINTS.ADMIN_GET_ALL_JOBS);
  },
  postNewJob(payload) {
    return axiosPublic.post(API_ENDPOINTS.ADMIN_POST_NEW_JOB, payload);
  },
  getAdminProfile(email) {
    return axiosPrivate.get(
      queryConcatenator(API_ENDPOINTS.ADMIN_PROFILE, { email })
    );
  }
};
