<template>
    <AuthLayout type="admin">
        <h1 class="fs-32 mb-2">Admin Login</h1>
        <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="handleSubmit">
                <Input
                    type="email"
                    placeholder="Email Address"
                    v-model="formData.email"
                    name="Email address"
                    rules="required|email"
                    class="mb-2"
                />
                <Input
                    type="password"
                    placeholder="Enter New Password"
                    v-model="formData.password"
                    name="Password"
                    rules="required|between:6,21"
                    autocomplete="current-password"
                    passwordToggleType="icon"
                    class="mb-1"
                />
                <div class="flex flex__end mb-2">
                    <router-link
                        class="text-blue fs-14"
                        :to="{ name: 'adminForgotPassword' }"
                        >Forget Password ?</router-link
                    >
                </div>
                <Button
                    :loading="loading"
                    :disabled="invalid || loading"
                    theme="blue"
                >
                    Login
                </Button>
            </form>
        </ValidationObserver>
    </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import adminService from "../../../../services/api/adminService";
import { mapMutations } from "vuex";

export default {
    name: "AdminLogin",
    components: {
        AuthLayout
    },
    data() {
        return {
            formData: {},
            loading: false
        };
    },
    methods: {
        ...mapMutations({
            setLoggedUser: "auth/setLoggedUser"
        }),
        async handleSubmit() {
            this.loading = true;
            try {
                const loggedUser = await adminService.signIn(this.formData);
                console.log(loggedUser);
                this.setLoggedUser(loggedUser);

                this.$notify({
                    text: "User logged in successfully",
                    title: "Success",
                    type: "success"
                });

                this.$router.push({
                    name: "adminDashboard"
                });
            } catch (error) {
                this.$notify({
                    text: error,
                    title: "Error",
                    type: "error"
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped></style>
